.image-modal{
  height: 100%;
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 111111;
  display: flex;
  align-items: center;

  &__zapfen{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: calc(25 * var(--vh));
    width: auto;
    opacity: 0.15;
  }

  &__label{
    position: absolute;
    bottom: 3vh;
    color: #fff;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: underline;
  }

  img{
    width: 100%;
  }

}
