.image-points {
  position: relative;
  height: 60%;
  display: flex;
  align-items: center;

  &__container {

    width: 100%;
  }

  &__image {
    width: 100%;
    position: relative;

    img {
      display: block;
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__point {
    position: absolute;
    outline: none;
    border: none;
    padding: 0;
    background-color: var(--green);
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    color: #fff;
    margin-top: -2vh;
    margin-left: -2vh;
    font-size: 2.5vh;
    font-weight: bold;
    font-family: var(--font1);

    &:before {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 0.2vh solid #fff;
      width: 75%;
      height: 75%;
      border-radius: 50%;
    }
  }
}