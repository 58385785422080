.center-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 11111;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #CB0C0F;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #00671F;
    opacity: 0.6;
    z-index: 1;
    animation-name: animationBackgroundModal;
    animation-duration: 0.5s;
  }

  &__modal {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    background-color: #fff;
    border-radius: calc(1.5 * var(--vh));
    z-index: 2;
    animation-name: animationBottomModal;
    animation-duration: 0.5s;
  }

  &__close {
    position: absolute;
    right: calc(2 * var(--vh));
    top: calc(2 * var(--vh));
    width: calc(5 * var(--vh));
    height: calc(5 * var(--vh));
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    z-index: 2;
  }

  &__line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(1.5 * var(--vh));
    width: calc(7 * var(--vh));
    height: auto;
  }

  &__content {
    // padding-top: calc(9 * var(--vh));
    padding-left: calc(1.5 * var(--vh));
    padding-right: calc(1.5 * var(--vh));
    text-align: center;
    height: calc(100% - 9vh);
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
  }

  &__zapfen {
    position: absolute;
    width: calc(40 * var(--vh));
    height: auto;
    right: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;

    path {
      fill: #EFF7F2;
    }
  }

  button {
    margin-top: auto;
    margin-bottom: calc(5 * var(--vh));
  }

  @keyframes animationBottomModal {
    from {
      bottom: -100%;
    }

    to {
      bottom: 0;
    }
  }

  @keyframes animationBackgroundModal {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.6;
    }
  }
}