.navigation {
  position: relative;
  left: 0;
  bottom: 0;
  background-color: transparent;
  height: calc(11.5 * var(--vh));
  width: 100%;
  z-index: 111;

  &__arrow {
    position: absolute;
    top: calc(-3 * var(--vh));
    height: calc(6 * var(--vh));
    width: auto;

    &--left {
      left: 4vw;
    }

    &--right {
      right: 4vw;
    }
  }

  &__home {
    position: absolute;
    margin-left: calc(-5 * var(--vh));
    top: calc(-5 * var(--vh));
    height: calc(10 * var(--vh));
    width: auto;
    left: 50%;
  }

  &__landscape {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}