@import "reset";

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

:root {
  --green: #00671f;
  --light-green: #eff7f2;
  --plyr-color-main: #00671f;
  --red: #cb0c0f;
  --font1: "Source Serif Pro", serif;
  --font2: "Open Sans", sans-serif;
  --yellow: #f7af4c;
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg) !important;
    transform-origin: left top !important;
    width: 100vh !important;
    overflow-x: hidden !important;
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    height: 100vw !important;
  }

  body {
    transform: none !important;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    position: relative !important;
    height: 100% !important;
  }
}

img {
  max-width: 100%;
}

$font1: "Source Serif Pro", serif;
$font2: "Open Sans", sans-serif;

body,
html {
  height: calc(100 * var(--vh));
  font-family: var(--font2);
  font-weight: 300;
  font-size: calc(1.95 * var(--vh));
  line-height: calc(2.5 * var(--vh));
  background-color: var(--light-green);

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  overscroll-behavior: contain;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-content {
    flex: 1;
    overflow-y: hidden;

    // &__landscape {
    //   position: fixed;
    //   width: 100%;
    //   bottom: 0;
    //   left: 0;
    //   display: block;
    //   z-index: 5
    // }
  }

  // background-color: #EFF7F2;
}

.h1 {
  font-size: calc(6.2 * var(--vh));
  line-height: calc(7.7 * var(--vh));
  font-weight: bold;
  font-family: var(--font1);
}

.h2 {
  font-family: var(--font1);
  font-size: calc(3.9 * var(--vh));
  line-height: calc(4.6 * var(--vh));
  font-weight: 400;
}

.h3 {
  font-weight: bold;
  font-size: calc(2 * var(--vh));
  line-height: calc(2.8 * var(--vh));
}

.text {
  &--red {
    color: var(--red);
  }
}

.app-button {
  background: #00671f;
  width: 100%;
  font-weight: bold;
  font-family: $font1;
  color: #fff;
  font-size: calc(2.2 * var(--vh));
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(6.2 * var(--vh));
  border: none;
  outline: none;
  transition: 0.3s background-color;

  &--outline {
    color: var(--green);
    border: 0.2vh solid var(--green);
    background-color: #fff;
  }

  &--yellow {
    background-color: var(--yellow);
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }

  &--full {
    width: 100%;
  }
}

.app-input {
  height: calc(6.2 * var(--vh));
  border: 0.2vh solid var(--green);
  padding: 0 calc(1 * var(--vh));
  font-size: calc(2.5 * var(--vh));
  outline: none;
  transition: 0.3s;
  box-shadow: none;
  border-radius: 0;

  &--error {
    border-color: var(--red);
  }

  &[readonly] {
    background-color: rgba(0, 103, 31, 0.2);
  }
}

button {
  transition: 0.3s opacity;

  &:disabled {
    opacity: 0.5;
  }
}

p {
  margin-bottom: calc(2 * var(--vh));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: calc(1.5 * var(--vh));
}

.app-container {
  padding: 0 calc(1.5 * var(--vh));
}

.scroll-box {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  // Scroll
  &::-webkit-scrollbar-button {
    // background-image: url("");
    background-repeat: no-repeat;
    width: calc(1 * var(--vh));
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--light-green);
  }

  &::-webkit-scrollbar-track {
    background-color: #4f4f4f;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--light-green);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--green);
    background-position: center;
    background-repeat: no-repeat;
  }

  &::-webkit-resizer {
    // background-image: url("");
    background-repeat: no-repeat;
    width: calc(1 * var(--vh));
    height: 0px;
  }

  &::-webkit-scrollbar {
    width: calc(1 * var(--vh));
  }
}
