.main-menu-layout {
  height: 100%;
  background-color: #fff;
  position: relative;

  &__zapfen{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: calc(40 * var(--vh));
    width: auto;
  }

  &__hero{
    height: 50%;
    position: relative;
    background-color: var(--light-green);

    &--border{
      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 3vh);
        height: calc(100% - 3vh);
        border: 0.2vh solid #fff;
        z-index: 22;
      }

      .main-menu-layout__heading{
        margin-top: calc(15 * var(--vh));
      }
    }
  }

  &__landscape {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    height: auto;
    width: 100%;
  }

  &__biergit{
    width: auto;
    height: calc(26 * var(--vh));
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  &__heading {
    position: absolute;
    left: calc(1.5 * var(--vh));
    top: 0;
    text-align: center;
    margin-top: calc(5 * var(--vh));
    max-height: calc(16 * var(--vh));
    overflow: hidden;
    width: calc(100% - 3vh);
    z-index: 1;
    color: var(--red);
    font-size: calc(6.2 * var(--vh));
    line-height: calc(7.7 * var(--vh));
    font-weight: bold;
    font-family: var(--font1);
  }

  &__navigation{
    display: flex;
    margin-top: auto;
    margin-top: calc(5 * var(--vh));
    flex-direction: column;

    button{
      margin-bottom: calc(2 * var(--vh));

      &:nth-child(1) {
        background-color: var(--yellow);
        color: #000;
        border-color: var(--yellow);
      }
    }
  }

}
