.link-popup {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    color: var(--green);
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-decoration: underline;
  }
}