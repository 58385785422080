.white-layout {
  height: 100%;
  background-color: #fff;
  position: relative;

  &__zapfen {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: calc(44 * var(--vh));
    width: auto;

    path {
      fill: var(--light-green);
    }
  }

  &__landscape {
    position: fixed;
    width: 100%;
    height: auto;
    // bottom: 40px;
    // bottom: calc(7 * var(--vh));
    bottom: 0;
    left: 0;
    // z-index: 2;
  }

  &__back {
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-top: calc(3 * var(--vh));
    padding-bottom: calc(4 * var(--vh));
    font-size: calc(2.2 * var(--vh));
    padding-left: 0;
    padding-right: 0;

    svg {
      margin-right: calc(1 * var(--vh));
      height: calc(2 * var(--vh));
      width: auto;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 15%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      color: var(--red);
      font-size: calc(6.2 * var(--vh));
      line-height: calc(7.7 * var(--vh));
      font-weight: bold;
      font-family: var(--font1);
    }
  }

  &__image {
    text-align: center;
    margin-bottom: calc(8.5 * var(--vh));

    img {
      height: calc(25 * var(--vh));
    }
  }

  &__description {
    max-height: 70%;
    position: relative;
    margin-bottom: 5%;
    overflow: hidden;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: calc(2.4 * var(--vh));
      font-weight: 700;
    }


    // &:after {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   width: calc(100% - (1 * var(--vh)));
    //   height: calc(10 * var(--vh));
    //   // background: linear-gradient(to top, #fff, transparent); 
    //   background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.2), #fff 100%);
    //   background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2), #fff 100%);
    //   background: -o-linear-gradient(top, rgba(255, 255, 255, 0.2), #fff 100%);
    //   background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.2), #fff 100%);
    //   background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), #fff 100%);
    // }
  }

  &__buttons {
    display: flex;
    margin-top: auto;
    margin-bottom: calc(4 * var(--vh));

    button:nth-child(2) {
      margin-left: calc(2 * var(--vh));
    }
  }

  &__link {
    font-weight: bold;
    text-align: center;
    margin-top: calc(1 * var(--vh));

    button {
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: 0.2vh solid #000;
      padding: 0;
      font-weight: bold;
    }

    margin-bottom: calc(6.5 * var(--vh));
  }

  &__checkboxes {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
  }
}