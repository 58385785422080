.desktop-placeholder {
  background-color: var(--light-green);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__heading {
    text-align: center;
    margin-bottom: 100px;
    z-index: 1;
    color: var(--red);
    font-size: 50px;
    line-height: 1.4;
    font-weight: bold;
    font-family: var(--font1);
  }

  &__logo {
    height: 50%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
