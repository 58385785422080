$root: '.map';

#{$root} {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__banner {
    height: calc(12.5 * var(--vh));
    background-color: #fff;
    text-align: center;

    &-duration {
      color: var(--green);
      padding: calc(1 * var(--vh)) 0;
    }
  }

  &__container {
    width: 100%;
    height: 100%;

    #{$root}__render {
      width: 100%;
      height: 100%;
    }
  }

  &__move {
    &-controls {
      position: absolute;
      bottom: 18vh;
      left: 4vh;

      button {
        padding: 0;
        width: 3vh;
        height: 3vh;
        opacity: 0.5;
        position: absolute;
        border: 0.2vh solid;
        font-weight: bold;
        font-size: 2.5vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-up {
      top: 0;
      left: 0;
    }

    &-down {
      top: 3.2vh;
      left: 0;
    }

    &-left {
      top: 3.2vh;
      left: -3.2vh;
    }

    &-right {
      top: 3.2vh;
      left: 3.2vh;
    }
  }

}