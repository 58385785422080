.birgit-message {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &>div {
    height: 100%;
  }

  &__message {
    position: relative;
    font-size: calc(2.8 * var(--vh));
    line-height: calc(3.2 * var(--vh));
    font-family: var(--font1);
    font-weight: 500;
    width: 100%;


    &-wrapper {
      height: 50%;
      display: flex;
      align-items: flex-end;
    }

    svg {
      height: auto;
      width: 100%;
    }

    &-text {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 75%;
      max-height: 70%;
      overflow: hidden;
      padding-left: 20%;
      padding-right: 5%;
      margin-top: -5%;
    }
  }

  &__biergit {
    height: 50%;
    text-align: center;

    svg {
      height: 100%;
      width: auto;
    }
  }

}