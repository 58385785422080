.progress-loader {
  animation: fadeStart 1s linear;
  animation-fill-mode: forwards;
  animation-delay: 3.5s;
  @keyframes fadeStart {
    to {
      opacity: 0;
    }
  }

  svg {
    width: calc(30 * var(--vh));
    height: calc(30 * var(--vh));
    animation: scale 1s linear;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
    @keyframes scale {
      to {
        transform: scale(0.5);
      }
    }
  }

  text-align: center;

  &__background,
  &__progress {
    fill: none;
  }

  &__background {
    stroke: #fff;
  }

  &__progress {
    stroke: var(--green);
    // stroke-linecap: round;
    stroke-linejoin: round;
    // transition: 0.7s linear;

    animation: dash 1s linear;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
    @keyframes dash {
      to {
        stroke-dashoffset: 0;
        stroke: var(--red);
      }
    }
  }

  &__text {
    font-size: calc(7 * var(--vh));
    font-weight: bold;
    fill: var(--green);

    animation: color 1s linear;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
    @keyframes color {
      to {
        fill: var(--red);
      }
    }

    tspan {
      font-size: calc(4 * var(--vh));
    }
  }
}
