.settings {
  text-align: left;
  margin-top: 5%;

  &__heading {
    font-family: var(--font1);
    font-size: calc(2.3 * var(--vh));
    line-height: calc(3.2 * var(--vh));
    font-weight: 700;
    margin-bottom: 2%;
  }

  &__list {
    background: #F8F8F8;
    border: 1px solid #C1C1C1;
    border-radius: 3px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      &:not(:last-of-type) {
        border-bottom: 1px solid #C1C1C1;
      }
    }

    &-button {
      background-color: transparent;
      padding: 0;
      outline: none;
      margin-bottom: 0 !important;
      border: none;
      font-size: calc(2 * var(--vh));
      line-height: 1;
      text-decoration: underline;
      font-weight: 400;
      padding-right: 20px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 2px;
        background-image: url('/images/Arrow.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
      }
    }

    &-label {
      line-height: 1;
      font-size: calc(2 * var(--vh));
      font-weight: 400;
    }
  }
}