.submit-form {
  &__form {
    padding-top: 5%;
  }

  &__title {
    margin-bottom: 2%;
  }

  &__textarea {
    height: 70% !important;
    padding: 2% !important;
    width: 95%;
    resize: none;
    margin-bottom: 5%;

    &::placeholder {
      transition: opacity 0.3s ease;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }
}