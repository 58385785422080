$textAninmationDelay: 1s;
$textAninmationDuration: 1s;
$borderAninmationDelay: 0.5s;
$borderAninmationDuration: 1.5s;

.text-full-screen {
  z-index: 2;
  position: relative;
  // padding-top: calc(5 * var(--vh));

  &__description {
    overflow: auto;
    opacity: 0;
    animation: showText $textAninmationDuration ease;
    animation-fill-mode: forwards;
    animation-delay: $textAninmationDelay;
    font-size: calc(2.3 * var(--vh));
    line-height: calc(3.2 * var(--vh));
    padding-top: 2%;
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  @keyframes showText {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

}