.first-loading{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light-green);

  &__header{
    width: 100%;
    height: calc(4 * var(--vh));
    background-color: var(--green);
    position: relative;

    svg{
      position: absolute;
      right: 4vw;
      top: 100%;
      width: auto;
      height: calc(8 * var(--vh));
    }
  }

  &__footer{
    svg{
      display: block;
      width: 100%;
      height: auto;
      // bottom: 40px;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
}