$textAninmationDelay: 0.5s;
$textAninmationDuration: 1s;
$zapfenAninmationDuration: 1.5s;
$borderAninmationDelay: 0.5s;
$borderAninmationDuration: 1.5s;

.heading-description {
  z-index: 2;
  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.15;
    z-index: 0;
    height: calc(21 * var(--vh));
    width: auto;
  }

  &__zapfen-small {
    display: none;
    position: absolute;
    right: 4vw !important;
    top: 100% !important;
    width: auto;
    height: calc(8 * var(--vh)) !important;
  }


  &__heading {
    height: calc(9 * var(--vh));
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    animation: showText $textAninmationDuration ease;
    animation-fill-mode: forwards;
    animation-delay: $textAninmationDelay;
    padding-top: 2%;
  }

  &__heading-wrapper {
    height: 0;
    position: relative;
    margin-bottom: calc(2 * var(--vh));

    animation: headingHeight $borderAninmationDuration cubic-bezier(0.66, -0.49, 0.36, 1.53);
    animation-fill-mode: forwards;
    // animation-delay: $borderAninmationDelay;

    &:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 100%;
      width: 100%;
      height: 0.4vh;
      border-top: 0.4vh solid;
      border-color: inherit !important;
      animation: borderMove $borderAninmationDuration cubic-bezier(0.66, -0.49, 0.36, 1.53);
      animation-fill-mode: forwards;
      // animation-delay: $borderAninmationDelay;

      @keyframes borderMove {
        from {
          bottom: 100%;
        }

        to {
          bottom: -0.4vh;
        }
      }
    }

    @keyframes headingHeight {
      from {
        // opacity: 0;
        height: calc(0 * var(--vh));
      }

      to {
        height: calc(10 * var(--vh));
        padding-bottom: calc(1 * var(--vh));
      }
    }
  }

  &__description {
    // height: calc(69 * var(--vh));
    // overflow: auto;
    opacity: 0;
    animation: showText $textAninmationDuration ease;
    animation-fill-mode: forwards;
    animation-delay: $textAninmationDelay;
  }

  @keyframes showText {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}