.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  button {
    padding: 0 10px;
    border-radius: 3px;
  }

  &>div {
    width: max-content;
  }
}