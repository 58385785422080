.top-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 222222;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--green);
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--green);
    opacity: 0.6;
    z-index: 1;
    animation-name: animationBackgroundModal;
    animation-duration: 0.5s;
  }

  &__modal {
    position: absolute;
    width: 95%;
    left: 50%;
    transform: translate(-50%,-50%);
    // top: calc(4 * var(--vh));
    top: 50%;
    // height: calc(70 * var(--vh));
    background-color: #fff;
    z-index: 2;
    animation-name: animationModal;
    animation-duration: 0.5s;
    margin-top: 0;
    padding-bottom: 2vh;
  }

  &__close {
    position: absolute;
    right: calc(1.5 * var(--vh));
    top: calc(1.5 * var(--vh));
    width: calc(4 * var(--vh));
    height: calc(4 * var(--vh));
    // box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    z-index: 2;
  }

  &__line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(1.5 * var(--vh));
    width: calc(7 * var(--vh));
    height: auto;
  }

  &__content {
    padding-top: calc(7 * var(--vh));
    padding-left: calc(1.5 * var(--vh));
    padding-right: calc(1.5 * var(--vh));
    text-align: center;
    height: calc(100% - 7vh);
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
  }

  &__zapfen {
    position: absolute;
    width: calc(40 * var(--vh));
    height: auto;
    right: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;
    path {
      fill: #eff7f2;
    }
  }

  &__title {
    max-height: calc(10 * var(--vh));
    overflow: hidden;
    color: var(--red) !important;
    font-size: 3.5vh !important;
  }

  &__description {
    max-height: calc(38 * var(--vh));
    overflow: auto;

    &::-webkit-scrollbar-button {
      // background-image: url("");
      background-repeat: no-repeat;
      width: calc(1 * var(--vh));
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--light-green);
    }

    &::-webkit-scrollbar-track {
      background-color: #4f4f4f;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--light-green);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--green);
      background-position: center;
      background-repeat: no-repeat;
    }

    &::-webkit-resizer {
      // background-image: url("");
      background-repeat: no-repeat;
      width: calc(1 * var(--vh));
      height: 0px;
    }

    &::-webkit-scrollbar {
      width: calc(1 * var(--vh));
    }
  }

  &__buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3vh;
  }

  button {
    margin-top: auto;
    &:nth-child(2){
      margin-left: 2vh;
    }
    // margin-bottom: calc(5 * var(--vh));
  }

  @keyframes animationModal {
    from {
      margin-top: calc(-5 * var(--vh));
    }
    to {
      margin-top: 0;
    }
  }

  @keyframes animationBackgroundModal {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }
}
