.text-with-button{
  height: 55%;
  overflow: hidden;
  position: relative;
  padding: 0 40px;
  background-color: var(--light-green);

  &__zapfen{
    position: absolute;
    // right: 15px;
    right: 4vw;
    top: 0;
    width: auto;
    // height: 65px;
    height: calc(8 * var(--vh));
  }

  &__text{
    margin-bottom: calc(3 * var(--vh));
    padding-top: calc(8 * var(--vh));
    max-height: calc(25 * var(--vh));
    overflow: hidden;
  }

}