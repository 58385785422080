.welcome-page {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;

  &__video{
    width: 100%;

  }

  &__button{
    position: absolute;
    width: calc(100% - 3vh) !important;
    left: 50%;
    bottom: calc(4 * var(--vh));
    transform: translateX(-50%);
  }

  :global{
    .plyr__video-wrapper{
      transition: 0.3s;
    }

    .plyr--video{
      background-color: transparent;
    }
    
    .plyr--stopped{
      .plyr__video-wrapper{
        opacity: 0 !important;
      }
      .plyr__control--overlaid[data-plyr="play"]{
        opacity: 1 !important;
      }
    }

    .plyr__control--overlaid[data-plyr="play"]{
      transition: 0.3s;
      opacity: 0 !important;
      width: 100%;
      height: 100%;
      visibility: visible !important;
      border-radius: 0;
      background-color: transparent;

      svg{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(3 * var(--vh));
        height: calc(3 * var(--vh));
      }

      &:before{
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(10 * var(--vh));
        height: calc(10 * var(--vh));
        border-radius: 50%;
        border: 0.4vh solid var(--green);
      }

      svg{
        fill: var(--green);
      }
    }
  }
}