.progress-loader {
  svg{
    width: calc(19 * var(--vh));
  }

  text-align: center;

  &__background,
  &__progress {
    fill: none;
  }

  &__background {
    stroke: #fff;
  }

  &__progress {
    stroke: var(--green);
    // stroke-linecap: round;
    stroke-linejoin: round;
    // transition: 0.7s linear;
  }

  &__text {
    font-size: calc(4 * var(--vh));
    fill: var(--green);
  }
}


