.main-layout {
  height: 100%;
  background-color: #EFF7F2;
  position: relative;

  &__zapfen {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: calc(40 * var(--vh));
    width: auto;
  }

  &__landscape {
    position: fixed;
    width: 100%;
    height: auto;
    // bottom: 40px;
    // bottom: calc(7 * var(--vh));
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__content {
    // position: relative;
    z-index: 1;
    height: 100%;
    // overflow: auto;
  }
}