$zapfenAninmationDuration: 1.5s;

.chapter-steps {
  &--style1 {
    .heading-description {
      background-color: var(--green);

      *:not(svg) {
        color: #fff;
        border-color: #fff;
      }
    }
  }

  &--style2 {
    position: relative;

    .heading-description {
      background-color: transparent;

      *:not(svg) {
        color: var(--red);
        border-color: var(--red);
      }

      svg {
        display: none;
      }
    }

    .big-zapfen {

      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      // height: calc(40 * var(--vh));
      width: auto;
      max-height: 90%;

      animation: zpfenScale $zapfenAninmationDuration cubic-bezier(.55, .17, .61, 1.47);
      animation-fill-mode: forwards;
      // animation-delay: 3.5s;

      @keyframes zpfenScale {
        from {
          height: calc(10 * var(--vh));
        }

        to {
          height: calc(40 * var(--vh));
        }
      }


    }
  }

  &--style3 {
    .heading-description {
      background-color: var(--green);

      *:not(svg) {
        color: #fff;
        border-color: #fff;
      }

      .small-zapfen {
        display: block;
        opacity: 1 !important;
      }
    }
  }
}