.chapter-steps {
  height: 100%;
  background-color: var(--light-green);

  &__item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;


    &>* {
      flex-shrink: 0;
    }
  }
}