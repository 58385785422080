.language-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  &__button {
    padding: 5px;
    width: 50px;
    height: 43px;
    box-sizing: border-box;

    svg {
      width: 100%;
      height: 100%;
    }

    &--active {
      border: 1px solid var(--green);
      padding: 5px;
    }
  }
  &__separator {
    padding: 0 5px;
  }
}
