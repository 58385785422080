.text-input {
  height: 100%;
  background-color: var(--light-green);

  &__form {
    position: relative;
    height: 64.5%;

    label {
      margin-bottom: calc(1 * var(--vh));
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  &__zapfen {
    position: absolute;
    right: 4vw;
    top: 0;
    width: auto;
    height: calc(8 * var(--vh));
  }

  &__landscape {
    position: fixed;
    // bottom: calc(5 * var(--vh));
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
  }

  &__form-input {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(4 * var(--vh));
  }

}