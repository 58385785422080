.checkbox {
  position: relative;

  input {
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;

    &:checked + label{
      
      // span{
      //   opacity: 1;
      // }

      svg{
        opacity: 1;
      }
    }
  }

  label{
    display: flex;
    padding-left: calc(8 * var(--vh));
    min-height: calc(6 * var(--vh));
    align-items: center;

    span{
      display: block;
      width: calc(6 * var(--vh));
      height: calc(6 * var(--vh));
      background-color: var(--green);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      // opacity: 0.5;
      transition: 0.3s;
    }

    svg{
      width: calc(4 * var(--vh));
      height: calc(4 * var(--vh));
      opacity: 0;
      transition: 0.3s;
    }
  }
}